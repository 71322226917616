import React from "react";
import logo from "../images/logo.svg";

const About = () => {
  return (
    <div>
      <header className="App-header">
        <div className="p-5">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>About</h1>
          <p>Testing IPFS _redirects.</p>
        </div>
      </header>
    </div>
  );
};

export default About;
