import React from "react";
import logo from "../images/logo.svg";

const Home = () => {
  return (
    <div>
      <header className="App-header">
        <div className="p-5">
          <img src={logo} className="App-logo" alt="logo" />
          <h1 className="text-2xl mb-10">
            I help implement ideas by leveraging technology.
          </h1>

          <div className="grid grid-cols-2 text-sm mb-10">
            <div className="mr-5 mx-auto flex justify-right">
              <img
                src={require("../images/gerrit.png")}
                alt="Gerrit"
                width="100"
              />
            </div>
            <div>
              <div className="text-left mb-2">
                <a
                  href="https://www.linkedin.com/in/gerritg/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="inline-block"
                    width="28"
                    src={require("../images/linkedin-128.png")}
                    alt="View the LinkedIn profile of Gerrit Giliomee"
                  />{" "}
                  Gerrit Giliomee
                </a>
              </div>
              <div className="text-left mb-2">
                <a href="tel:0724860718" target="_blank" rel="noreferrer">
                  0724860718
                </a>
              </div>
              <div className="text-left">
                <a
                  href="mailto:hello@nudge.tech"
                  target="_blank"
                  rel="noreferrer"
                >
                  hello@nudge.tech
                </a>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 text-sm">
            <div className="text-left sm:mr-5 mb-2">
              <ul className="sm:text-right">
                <li>Full Stack Laravel / VueJs</li>
                <li>Coaching & Mentoring</li>
                <li>Project Management</li>
                <li>Web3</li>
                <li>DevOps</li>
                <li>AI Integration</li>
              </ul>
            </div>
            <div className="text-left mb-2">
              Full stack developer with a strong business background, working
              and living around Knysna, South Africa. Interested in the impact
              that technology can have on society. Excited about web3.
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Home;
